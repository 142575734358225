// Import dependencies
import React from "react";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";

// Import styles
import "./404.css";
import { PageContainer, ParagraphText, TitleText } from "../styled-components/UILibrary";
import HeaderSeparator from "../components/separators/headerSeparator";
import TitleSection from "../components/sections/titleSection";
import { Separator } from "../components/separators/separator";
import SearchBar from "../components/search-bar/searchBar";
import Tiger from "../assets/images/404_tiger.png";

import { getFirebase } from "../api/firebase";
import { navigate } from "gatsby";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  return (
    browser && (
      <Layout>
        <SEO title="404: Not found" />
        <div className="only-mobile">
        </div>
        <SearchBar/>
    
        {/* Page content */}
        <PageContainer>
          <div className="container_404">
            <h1>404</h1>
            <p>An angry tiger has taken over this page</p>
          </div>
          <img className="refer-friends-header-icon" src={Tiger}/>
    
        </PageContainer>
      </Layout>
    )
  );
};

export default NotFoundPage;